.section {
  padding: 0px 0px 0px 0px;
  margin: 1.5rem 0px 1.5rem 0px;
}

.header-btn {
  background-color: transparent;
  border: none;
  width: 130px;
  height: 40px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 24px;
  gap: 10px;
}

.subs-btn {
  white-space: nowrap;
  width: max-content !important;
  padding: 8px 26px 8px 26px !important;
}

.btn-login {
  background: linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  color: white;
}

.btn-start {
  border: 1px solid #3274ba;
  color: #3274ba;
  transition: 0.4s ease;
}

.bullet-check {
  color: #3274ba;
  margin-right: 5px;
}

.nav-color {
  height: 121px;
  padding: 26px, 37px, 25px, 30px;
  border: 0px, 0px, 0.5px, 0px;
  background: #367db0;
  box-shadow: 0px 6px 8px 0px #00000040;
}

.btn-start:hover {
  background: linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  color: white;
}

.hero-heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c2c2c;
  margin-bottom: 2rem;
}

.hero {
  margin-top: 7rem;
}

.hero-desc {
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

.assesment-btn {
  background: linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  color: white;
  border-radius: 25px;
  width: 260px;
  padding: 8px, 16px, 8px, 16px;
  font-size: 16px;
  margin: 2rem 0;
  white-space: nowrap;
}

.landing-img {
  width: 600px;
}

.payment-card__plan-card {
  border: 1px solid #e4e4e4;
  background: linear-gradient(0deg, #fcfcfc, #fcfcfc);
  padding: 15px;
  border-radius: 8px;
}

.assements-form {
  margin: 1rem;
}

.assements-form input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.assements-form label {
  margin-left: 10px;
  cursor: pointer;
}

.form-check-label {
  margin-top: 2px;
}

.list-icon {
  font-size: 1.7rem;
  cursor: pointer;
}

.back-btn {
  color: black;
  font-size: 3rem;
}

.form-head {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.form-head span {
  color: red;
  font-size: 0.8rem;
}

.form-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.cancel-modal-btn {
  background: #3274ba;
  color: white;
  border-radius: 5px;
  width: 150px;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  border: none;
}

.delete-modal-btn {
  background: #ff0000;
  color: white;
  border-radius: 5px;
  width: 150px;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  border: none;
}

.required {
  color: red;
  font-size: 1.5rem;
  line-height: 0;
  transform: translate(-2px, 12px);
}

.sig-preview {
  margin: auto 0;
}

.update-sub {
  white-space: nowrap;
  width: max-content !important;
  padding: 6px 15px;
  border-radius: 25px;
}

.btn-user {
  background: #3f7f9a;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-x {
  color: #ff0000;
  font-size: 25px;
}

.delete-modal-btn {
  background: #ff0000;
  color: white;
  border-radius: 5px;
  width: 150px;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  border: none;
}

.badge-warning {
  background-color: #ff0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.badge-success {
  background-color: #4bb543;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.btn-review {
  background-color: #23195f;
  padding: 4px 12px;
  white-space: nowrap;
  font-size: 12px;
  color: white;
}

.btn-review:hover {
  background-color: #403192;
  color: white;
}

.approve-btn {
  background: linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  padding: 8px 16px;
  white-space: nowrap;
  color: white;
  white-space: nowrap;
  border: none;
  border-radius: 25px;
}

.download-btn {
  border: 1px solid linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  padding: 8px 16px;
  white-space: nowrap;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  border-radius: 25px;
  background-color: transparent;
}

.assessment-content-key {
  font-weight: 600;
  font-size: 20px;
}

.key-icon {
  font-size: 1.5rem;
  margin-left: 15px;
  cursor: pointer;
}

.report-content {
  font-size: 18px;
}

.save-btn {
  background: linear-gradient(89.99deg, #9664d1 0.01%, #3274ba 99.99%);
  color: white;
  padding: 4px 16px;
  white-space: nowrap;
  border: none;
  font-size: 18px;
  border-radius: 5px;
}
