.assements-form {
  overflow-y: scroll;
  height: 60vh;
}

.assements-form > div:nth-child(even) {
  background-color: #f2f2f2;
}

.switch {
  display: inline-block;
}

.switch input {
  display: none;
}

.switch label {
  display: block;
  width: 60px;
  height: 30px;
  padding: 3px;
  border-radius: 15px;
  border: 2px solid #000000;
  cursor: pointer;
  transition: 0.3s;
}

.switch label::after {
  content: "";
  display: inherit;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background: #000000;
  transition: 0.3s;
}

.switch input:checked ~ label {
  border-color: #000000;
}

.switch input:checked ~ label::after {
  translate: 30px 0;
  background: #000000;
}

.switch input:disabled ~ label {
  opacity: 0.5;
  cursor: not-allowed;
}

.assessment-content-value {
  width: 100%;
}
