body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

.header-color {
  background-color: #367db0 !important;
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}
